import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class WebsiteDescription extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section bg-web-desc">
                    <div className="bg-overlay"></div>
                    <Container>
                        <Row>
                            <Col lg="12" className="text-center">
                                <h2 className="text-primary">Take the first step now</h2>
                                <h4 className="pt-3 home-desc mx-auto text-white">Onboarding is quick & easy, simply sign up for an initial appointment. 
                                <br /><br />
                                From there, your assigned account
                                manager will guide you step-by-step <br /><br />through the process, tailored to the specific conditions of your project.</h4>
                                <a href="#contact" class="btn btn-custom margin-t-30 waves-effect waves-light">Schedule Now</a>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default WebsiteDescription;