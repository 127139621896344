import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
    state = {
        services : [
            { title : "Groundfloor M.V.P.", icon : "pe-7s-diamond", description : "A quick to market service or product, built on a stack of vetted & scoped SaaS tools. If an idea is all you have so far, we will start here." },
            { title : "[Any] Web Development", icon : "pe-7s-display2", description : "Our studio specialty - high quality full stack web app development focused on LAMP, React, Vue.js or nearly anything else, the sky is the limit here." },
            { title : "UI/UX & Prototypes", icon : "pe-7s-piggy", description : "User research, design iterations and high fidelity mockups bring your users into focus, allowing your leadership teams to make pinpoint decisions. " },
            { title : "No Code", icon : "pe-7s-science", description : "Business focused apps with limited scalability. Test ideas in the market quickly or directly outpace the competition to market." },
            { title : "Development on Demand", icon : "pe-7s-news-paper", description : "Release your agency's potential, let us handle the programming, servers and technical deliverables. Whether it's overflow or complete white label service." },
            { title : "Technical Project Management", icon : "pe-7s-plane", description : "Our experienced staff lend themselves to managing your offshore teams, internal groups or customers through highly technical processes." },
            { title : "Low Code", icon : "pe-7s-arc", description : "Enterprise level integration systems built on strong programming foundations that can scale under our watch within the cloud." },
            { title : "Infastructure Development", icon : "pe-7s-tools", description : "Let us guide you on how to connect your app to cloud infastructure for customer, staff or user access around the globe." },
            { title : "Automations & Process", icon : "pe-7s-timer", description : "We provide variable impact automations at any project stage. Primarily targeted to SMBs or small orgs with big KPIs or quick timelines to hit via minimal cost. " },
            { title: "Full Stack", icon: "pe-7s-timer", description: "We carefully approach your needs and projected scope to provide stack guidance, integrate into an existing codebase or start dev from scratch." },
            { title: "Existing Technology Updates", icon: "pe-7s-timer", description: "Retrofitting internal or customer facing software is a daunting task - we take it one piece at a time to provide micro-level detailed plans of action." },
            { title: "OK With The Weird", icon: "pe-7s-timer", description: "Our group thrives on fun, creative and interesting web app projects. If you have something that doesn't fit the mold, we would love to hear about it." },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="services">
                    <Container>

                        {/* Render section title */}
                        <SectionTitle title="Our Services" description="Intentionally kept small, our team of specialists prefer to attack each project individually to isolate the best possible outcome for the budget, timeline and mission-critical objectives"/>

                        <Row className="margin-t-30">
                            {/* render service box */}
                            {
                                this.state.services.map((service) =>
                                    <ServiceBox title={service.title} icon={service.icon} description={service.description} />
                                )
                            }
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Services;