import React, { Component } from 'react';
import { Col } from "reactstrap";

class TestimonialBox extends Component {

    render() {
        return (
            <React.Fragment>
                            <Col lg="3">
                                <div className="testimonial-box margin-t-30">
                                    <div className="testimonial-decs p-4">
                                        <div className="testi-icon">
                                            <i className="mdi mdi-format-quote-open display-2"></i>
                                        </div>
                                        <div className="p-1">
                                            <p className="text-muted text-center">{this.props.testimonial.message}</p>
                                            <h5 className="text-center text-uppercase mb-3">{this.props.testimonial.name} - <span className="text-muted text-capitalize">{this.props.testimonial.cmpName}</span></h5>
                                        </div>
                                    </div>
                                </div>
                            </Col>
            </React.Fragment>
        );
    }
}

export default TestimonialBox;