import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Testimonial Box
import TestimonialBox from "./testimonial-box";

class Testimonials extends Component {
    state = {
        testimonials : [
            { id : 1, image : "assets/images/testimonials/user-1.jpg", name : "K. Raines", cmpName : "MSFT", message : "“Timely, attentive and annoyingly critical, your team kills it every time and we greatly appreciate what you do.”" },
            { id: 2, image: "assets/images/testimonials/user-2.jpg", name: "D. Berg", cmpName: "Red Wind Casino", message: "“The crew at Voyager are critical to how we distribute our advertising into the market, they do great work and we would be lost without their coding expertise.”" },
            { id : 3, image : "assets/images/testimonials/user-3.jpg", name : "B. Roberson", cmpName : "Baden Sports", message : "“Best part of working with you guys is that I can submit a task and it will get done. On time. No crazy meetings. Within budget. I love it.”" },
            { id: 4, image: "assets/images/testimonials/user-3.jpg", name: "K. Murphy", cmpName: "UEG Worldwide", message: "“Thank you for your work on [redacted]. Once again it's apparent how dedicated you & the team are to the user experience as a whole. Keep up the great work." },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section" id="testimonials">
                    <Container>
                        {/* Render section title */}
                        <SectionTitle title="What they say" description="Voyager has worked with dozens of amazing clients throughout North America and the UK over the last half decade, but our heart belongs in the forests, valleys and coast of the great Pacific Northwest."/>

                        <Row className="margin-t-50">
                            {/* render testimonials box */}
                            {
                                this.state.testimonials.map((testimonial) =>
                                    <TestimonialBox testimonial={testimonial} />
                                )
                            }
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Testimonials;