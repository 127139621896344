import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

class Features extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section" id="intro">
                    <Container>
                        <Row className="">
                            <Col lg="5" className="order-2 order-lg-1">
                                <div className="features-box mt-5 mt-lg-0">
                                    <h3>A digital web studio focused on crafting creative technical solutions</h3>
                                    <p className="text-muted web-desc">More than just a code agency, our unique team is built upon years of professional experience to provide the absolute best web development for your constituents, business or project. </p>
                                    <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                                        <li className="">Dedicated account managers who work with <span className="font-500">you</span> directly</li>
                                        <li className=""><span className="font-500">US-Based</span> in wonderful Corvallis, Oregon </li>
                                        <li className="">Access to 24/7 <span className="font-500">Advanced Technical Support</span></li>
                                        <li className="">Multiple project verticals & programming languages</li>
                                    </ul>
                                    <a href="#contact" class="btn btn-custom margin-t-30 waves-effect waves-light">Contact Us Today <i className="mdi mdi-arrow-right"></i></a>
                                </div>
                            </Col>
                            <Col lg={{size: 7, order: 2, }} xs={{order : 1}}>
                                <div className="features-img mx-auto mr-lg-0 mt-1">
                                    <img src="assets/images/feature/employee_working.jpeg" alt="macbook" className="img-fluid rounded" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Features;