import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";


class Clients extends Component {
    state = {
        clients : [
            { id: 1, img : "assets/images/clients/client_microsoft.png" },
            { id: 2, img: "assets/images/clients/client_mohegan_sun.png" },
            { id: 3, img : "assets/images/clients/client_conde_nast.svg" },
            { id: 4, img: "assets/images/clients/client_realsimple.svg" },
            { id: 5, img: "assets/images/clients/client_paloAltoNetworks.svg" },
            { id: 6, img: "assets/images/clients/client_fbBusiness.svg" },
            { id: 7, img: "assets/images/clients/client_portLogisticsGroup.svg" },
            { id: 8, img: "assets/images/clients/client_citySacramento.png" },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section pt-0 mt-0 mb-5">
                    <Container>
                        <Row className="d-flex justify-content-around align-items-center align-self-center">
                            {
                                this.state.clients.map((client) =>
                                    <Col md="3">
                                        <div className="client-images my-3">
                                            <img src={client.img} alt="logo-img" className="mx-auto img-fluid d-block px-4"/>
                                        </div>
                                    </Col>                                
                                )
                            }
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Clients;