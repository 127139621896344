import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";

//Import Team Box
import TeamBox from "./team-box";


class AboutUs extends Component {
    state = {
        members : [
            { id : 1, name : "Frank Johnson", image : "assets/images/team/img-1.jpg", post : "CEO" },
            { id : 2, name : "Elaine Stclair", image : "assets/images/team/img-2.jpg", post : "DESIGNER" },
            { id : 3, name : "Wanda Arthur", image : "assets/images/team/img-3.jpg", post : "DEVELOPER" },
            { id : 4, name : "Joshua Stemple", image : "assets/images/team/img-4.jpg", post : "MANAGER" },
        ]
    }
    render() {
        return (
            <React.Fragment>
                <section className="section" id="about">
                    <Container>
                        <Row>
                            <Col lg={{size : 8, offset : 2}}>
                                <div className="about-title mx-auto text-center">
                                    <h2 className="font-weight-light">Helping organizations of any size reach thier goals</h2>
                                    <p className="text-muted pt-4">Our passion is in helping people everyday overcome their technical challenges and provide great experiences to their staff, customers and users. We do that via a deeply refined collaboration process that helps teams in orgs big or small achieve high elevation goals, including navigating pivot points during production. We do this as your sidekick, without the sluggish corporate timelines or overhead costs of a large developer farm.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default AboutUs;