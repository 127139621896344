import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Input } from "reactstrap";

//Import Footer link
import FooterLinks from "./footer-links";

class Footer extends Component {
     constructor(props) {
        super(props);
        this.state = {
        footerItems : [
            { title : "VOYAGER" ,
                links : [
                    { linkTitle : "Home", link : "#" },
                    { linkTitle : "About us", link : "#" },
                    { linkTitle : "Careers", link : "#" },
                    { linkTitle : "Contact us", link : "#" },
                ]
            },
            { title : "Information" ,
                links : [
                    { linkTitle : "Terms & Conditions", link : "#" },
                    { linkTitle : "Privacy Policy", link : "#" },
                    { linkTitle : "Accessibility Statement", link : "#" },
                ]
            },
            { title : "Support" ,
                links : [
                    { linkTitle : "FAQ", link : "#" },
                    { linkTitle: "Knowledgebase", link: "#" },
                    { linkTitle : "Support Portal", link : "#" },
                    { linkTitle : "Client Dashboard", link : "#" },
                ]
            },
        ],
        isSwitchToggle : false
    }
    this.toggleSwitcher = this.toggleSwitcher.bind(this);
    this.setTheme = this.setTheme.bind(this);
    }

    setTheme = (color) => {
        document.getElementById("colorTheme").href="assets/colors/"+color+".css";
    }

toggleSwitcher = () => {
    this.setState({ isSwitchToggle : !this.state.isSwitchToggle });
}
    render() {
        return (
            <React.Fragment>
                <footer className="footer d-none">
                    <Container>
                        <Row>
                            {
                                this.state.footerItems.map((item) =>
                                    <Col lg="3" className="margin-t-20">
                                        <h4>{item.title}</h4>
                                        <div className="text-muted margin-t-20">
                                            <ul className="list-unstyled footer-list">
                                                {
                                                    item.links.map((link) =>
                                                        <li><Link to={link.link}>{link.linkTitle}</Link></li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </Col>
                                )
                            }

                            <Col lg="3" className="margin-t-20">
                                <h4>Subscribe</h4>
                                <div className="text-muted margin-t-20">
                                    <p>Tagline</p>
                                </div>
                                <Form className="form subscribe">
                                    <Input placeholder="Email" className="form-control" required/>
                                    <Link to="#" className="submit"><i className="pe-7s-paper-plane"></i></Link>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </footer>
                
                <FooterLinks/>

            </React.Fragment>
        );
    }
}

export default Footer;