import React, { Component, useEffect } from 'react';
import { Container, Row, Col, Button } from "reactstrap";
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Section Title
import SectionTitle from "../common/section-title";

class ContactUs extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="contact">
                    <Container>
                        {/* Render section title */}
                        <SectionTitle title="Get In Touch" description="Have some questions? Want to see if we have a project fit? Send us a message using the form below and we'll reach out the next business day."/>

                        <Row>
                            <Col lg="4">
                                <div className="mt-4 pt-4">
                                    <p className=""><span className="h5">Corvallis* Office:</span><br/> <span className="text-muted d-block mt-2"><br />Corvallis, OR 97330</span></p>
                                    <p className="mt-4"><span className="h5">Portland* Office:</span><br /> <span className="text-muted d-block mt-2">700 SW 5th Avenue<br />
                                    #4000<br />
                                    3rd Floor<br />
                                    Portland, OR 97204</span></p>
                                    <p className="mt-4"><span className="h5">Working Hours:</span><br/> <span className="text-muted d-block mt-2">9:00AM To 4:00PM Pacific Time</span></p>
                                    <p className="mt-4 text-primary">*Due to Covid-19, our offices are currently closed<br /> to the public. We will be working remotely until at least Q2 of 2021. <br /><a href="https://govstatus.egov.com/or-covid-19" target="_blank" className="text-info text-small">
                                        >> COVID-19 Resources for Oregonians</a></p>
                                </div>
                            </Col>
                            <Col lg="8">
                                <div className="custom-form mt-4 pt-4">
                                    <div id="message"></div>
                                    <div id="formkeep-embed" data-formkeep-url="https://formkeep.com/p/a7336641c8f3f977a6fee3f1d4d55dcc?embedded=1"></div>
                                </div>  
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }

}

export default ContactUs;