import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Importing Modal
import ModalSection from '../../components/common/ModalSection';

class Section extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false
        }
        this.callModal.bind(this)
    }

    callModal = () => {
        this.refs.child.openModal();
    }
    
    render() {
        return (
            <React.Fragment>
                <section className="section bg-home home-half mb-3" id="home" >
                    <div className="bg-overlay"></div>
                    <Container>
                        <Row>
                            <Col lg={{size : 12}} className="text-white text-center mb-4">
                                <h4 className="home-small-title">Welcome Aboard</h4>
                                <h1 className="home-title">We Handle Complex Web Development for Startups, Digital Agencies & Government Offices</h1>
                                <p className="padding-t-15 home-desc mx-auto">World class web app development built start to finish, by a nimble team of elite software craftsman, accomplished project consultants & UI/UX artisans.</p>
                            </Col>
                            {/* Render ModalSection Component for Modal */}
                            <ModalSection ref="child" channel='vimeo' videoId='99025203' />
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Section;